import React from "react";
import Classes from "./suspense.module.css";
import Spinner from "../loaders/spinner";

const Suspense = () => {
  return (
    <center className={Classes.centerHERE}>
      <Spinner size={"sm"} />
    </center>
  );
};

export default Suspense;
