// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_button__ci4Vq{\n\n    cursor: pointer;\n    margin-top: 0.75rem;\n    font-weight: 400;\n    text-align: center;\n    vertical-align: middle;\n    padding: 0.35em 1em;\n    background-color: #ff0066;\n    color: #fff;\n    font-size: .875rem;\n    display: flex;\n    line-height: 1.5;\n    align-items: center;\n    outline: none;\n    border-radius: 8px;\n    font-size: 13px;\n    font-weight: bold;\n    justify-content: center;\n    border: 2px solid #ff0066;\n}\n.button_button__ci4Vq:disabled{\n    background-color: #ff006677;\n    border: 2px solid #ff006677;\n}", "",{"version":3,"sources":["webpack://./src/component/button/button.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B","sourcesContent":[".button{\n\n    cursor: pointer;\n    margin-top: 0.75rem;\n    font-weight: 400;\n    text-align: center;\n    vertical-align: middle;\n    padding: 0.35em 1em;\n    background-color: #ff0066;\n    color: #fff;\n    font-size: .875rem;\n    display: flex;\n    line-height: 1.5;\n    align-items: center;\n    outline: none;\n    border-radius: 8px;\n    font-size: 13px;\n    font-weight: bold;\n    justify-content: center;\n    border: 2px solid #ff0066;\n}\n.button:disabled{\n    background-color: #ff006677;\n    border: 2px solid #ff006677;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_button__ci4Vq"
};
export default ___CSS_LOADER_EXPORT___;
