import React, { useState } from "react";

import Classes from "./login.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import { States } from "../../data/stateJson";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import joinClasses from "../../utils/joinClasses";

const Login = ({
  inputs,
  setInputs,
  setSelectedStates,
  setRegister,
  register,
  onSubmitHandler,
  loading,
  CTX,
  setMsg,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPhoneMSG, setShowPhoneMSG] = useState(false);
  const [showPassMSG, setShowPassMSG] = useState(false);
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  const onBlurPhoneHandler = (e) => {
    console.log("CTX => ", CTX);
    if (e?.target?.value?.length !== 11) {
      setMsg("Invalid phone number");
      return;
    } else {
      setMsg("");
      return;
    }
  };

  const [agree, setAgree] = useState(false);

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Login - Passward Agent </title>
        <meta name="og:title" content="Login - Passward Agent" />
        <meta name="og:description" content="Login - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={joinClasses(Classes.relative, "overflow-hidden")}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>{register ? "Register" : "Login"}</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={onSubmitHandler}
        >
          {register && (
            <div className={Classes.main_width}>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  required={true}
                  value={inputs?.first_name}
                  onChange={(e) =>
                    setInputs({ ...inputs, first_name: e.target.value })
                  }
                  labelStyle={{ color: "#000033" }}
                  label="First Name"
                  style={{
                    backgroundColor: "#fff",
                    height: "50px",
                    border: "1px solid #ff0066",
                  }}
                  placeholder="First Name"
                />

                <Input
                  type="input"
                  required={true}
                  labelStyle={{ color: "#000033" }}
                  label="Last Name"
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({ ...inputs, last_name: e.target.value })
                  }
                  placeholder="Last Name"
                  style={{
                    backgroundColor: "#fff",
                    height: "50px",
                    border: "1px solid #ff0066",
                  }}
                />
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  type="select"
                  required
                  style={{
                    backgroundColor: "#fff",
                    height: "50px",
                    border: "1px solid #ff0066",
                  }}
                  labelStyle={{ color: "#000033" }}
                  options={["Choose state"].concat(
                    States.map((v) => {
                      return v.name;
                    })
                  )}
                  onChange={(e) => setSelectedStates(e.target.value)}
                  label="State"
                />
                <div>
                  <Input
                    type="input"
                    required={true}
                    onBlur={onBlurPhoneHandler}
                    labelStyle={{ color: "#000033" }}
                    label="Phone Number"
                    value={inputs?.phone_number}
                    onChange={(e) => {
                      setInputs({ ...inputs, phone_number: e.target.value });
                      if (e.target.value.length === 11) {
                        return setShowPhoneMSG(false);
                      } else {
                        return setShowPhoneMSG(true);
                      }
                    }}
                    placeholder="Phone Number"
                    style={{
                      backgroundColor: "#fff",
                      height: "50px",
                      border: "1px solid #ff0066",
                    }}
                  />
                  {showPhoneMSG && (
                    <>
                      {inputs?.phone_number?.length > 1 && (
                        <p className={Classes.must_Contain}>
                          Phone number must be 11 digits
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={Classes.main_width}>
            <Input
              type="input"
              placeholder="yourmail@domain.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              required={true}
              labelStyle={{ color: "#000033" }}
              label="Email"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
          </div>
          <div className={Classes.main_width} style={{ marginTop: "8px" }}>
            <Input
              type="input"
              required={true}
              value={inputs?.password}
              onChange={(e) => {
                setInputs({ ...inputs, password: e.target.value });
                if (re.test(e.target.value)) {
                  return setShowPassMSG(false);
                } else {
                  return setShowPassMSG(true);
                }
              }}
              labelStyle={{ color: "#000033" }}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? "text" : "password"}
              label="Password"
              style={{
                backgroundColor: "#fff",
                height: "50px",
                border: "1px solid #ff0066",
              }}
            />
            {register && (
              <>
                {showPassMSG && (
                  <>
                    {inputs?.password?.length > 1 && (
                      <p className={Classes.must_Contain}>
                        Password must contain an Uppercase,Number, Lowercase and
                        Symbol
                      </p>
                    )}
                  </>
                )}
                <div
                  style={{
                    marginTop: "8px",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div className="flex gap-x-2 items-center ">
                    <input
                      required
                      type="checkbox"
                      className={Classes.inputCheckbox}
                      checked={agree}
                      onClick={() => setAgree(!agree)}
                      alt=" "
                    />
                    <label className={Classes.coloredLabel}>
                      Agree to{" "}
                      <button
                        type="button"
                        onClick={() =>
                          window.open(
                            "https://passward.ng/terms-condition",
                            "_blank"
                          )
                        }
                        style={{ textDecoration: "underline" }}
                      >
                        Terms and Conditions
                      </button>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          {!register && (
            <div
              className={Classes.main_width}
              style={{ marginTop: "8px", flexDirection: "row" }}
            >
              <div>
                <input type="checkbox" className={Classes.inputCheckbox} />
                <label className={Classes.coloredLabel}>
                  Keep me Signed In
                </label>
              </div>
              <Link
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to="/forgot-password"
              >
                <label className={Classes.coloredLabel}>Forgot Password?</label>
              </Link>
            </div>
          )}
          {/* {!register && ( */}
          <div className={Classes.main_width} style={{ marginTop: "38px" }}>
            <Button
              text={register ? "Register" : "Login"}
              style={{ width: "100%", height: "40px", marginBottom: "50px" }}
              loading={loading}
              disabled={!agree && register}
            />
          </div>
          {/* )} */}
          <div className={Classes.centeredText}>
            {!register && (
              <span style={{ cursor: "pointer" }} onClick={setRegister}>
                Don’t have account?
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#FF0066",
                    textDecoration: "underline",
                  }}
                >
                  Register
                </span>
              </span>
            )}

            {register && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setRegister(!register)}
              >
                Already a User?
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#FF0066",
                    textDecoration: "underline",
                  }}
                >
                  Login
                </span>
              </span>
            )}
          </div>
        </form>
        <img src={SemiLogo} className={Classes.topRightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.rightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.leftLogo} alt="logo" />
        <img src={RightLogo} className={Classes.topLeftLogo} alt="logo" />
        <img src={SemiLogo} className={Classes.semiLogo} alt="logo" />
      </div>
    </div>
  );
};

export default Login;
