import React from "react";
import Classes from "./button.module.css";
import Spinner from "../loaders/spinner";

const Button = ({
  loading,
  onClick,
  style,
  text,
  classNames,
  showLoading = true,
  disabled,
}) => {
  return (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      style={{ ...style }}
      className={`${Classes.button} space-x-3  ${classNames}`}
    >
      <div>{text}</div>
      {showLoading && (
        <>{loading && <Spinner size={"xs"} color={"secondary"} />}</>
      )}
    </button>
  );
};

export default Button;
