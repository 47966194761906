import React, { useContext, useState, useEffect } from "react";
import {
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineShopping,
  AiOutlineSetting,
  AiOutlineWallet,
  AiOutlineDollarCircle,
} from "react-icons/ai";
import Classes from "./sidebar.module.css";
import { ReactComponent as Left } from "../../../asset/left.svg";
import { ReactComponent as Apps } from "../../../asset/apps.svg";
import { ReactComponent as Store } from "../../../asset/store.svg";
import { ReactComponent as Income } from "../../../asset/income.svg";
import { ReactComponent as Withdrawal } from "../../../asset/money_withdrawal.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal } from "../../modal/modal";
import { MainContext } from "../../../App";
import Button from "../../button/button";
import { GetUserDetails } from "../../../helper/getUserHelper";
import { GlobalContext } from "../../../context/GlobalProvider";

const Sidebar = () => {
  const CTX = useContext(MainContext);
  const { getStatistics, statistics } = useContext(GlobalContext);
  const [navAngle, setNavAngle] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const { getUser } = GetUserDetails();

  const onClickSettingHandler = (e) => {
    e.preventDefault();
    setShowSettings(!showSettings);
    setShowTransaction(false);
  };

  const onClickTransactionHandler = (e) => {
    e.preventDefault();
    setShowSettings(false);
    setShowTransaction(!showTransaction);
  };

  useEffect(() => {
    getUser();
    getStatistics();
  }, []);
  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      setNavAngle(window.scrollY);
    });
  }, []);

  // // removeSideBar from body
  // useEffect(() => {
  //   window.addEventListener("scroll",  e => {
  //     setFixSidebar(window.scrollY > 62)
  //   })
  //   return () => {
  //     window.removeEventListener()
  //   }
  // }, [])

  // removeSideBar from body
  const removeSideBar = () => {
    document.body.removeAttribute("data-side-navigation-mode");
    document.body.classList.remove("fade-effect");
  };

  const navigate = useNavigate();

  return (
    <>
      <aside
        className={`fixed lg:top-[100px] ${
          navAngle > 50 ? "lg:top-0" : "lg:top-[100px]"
        } transition-all top-0 ${
          !CTX.sidebar && "translate-x-[-332px]"
        } hideScrollBar lg:translate-x-0 h-full overflow-scroll z-10 `}
      >
        <div className={Classes.SidebarMainCover}>
          <div className={Classes.SidebarHeaderCover}>
            <div className={Classes.SidebarHeaderRoundCover}>
              {!CTX.user.photo ? (
                <AiOutlineUser size="2.3em" fill="#fff" />
              ) : (
                <img
                  src={CTX.user.photo}
                  alt="Profile"
                  className={Classes.ProfileIMG}
                />
              )}
            </div>
            <h5 className={Classes.SidebarUsername}>
              {CTX.user.first_name} {CTX.user.last_name}
            </h5>
          </div>
          <div className={` ${Classes.SidebarLinkCover}`}>
            <EachLink
              onClick={CTX.closeNav}
              svg={<Apps width="1.3em" />}
              name="Overview"
              to="/dashboard"
            />
            {/* <EachLink
          onClick={CTX.closeNav} svg={<AiOutlineUser size="1.3em" />} to="/profile" name="Profile" /> */}
            <EachLink
              onClick={CTX.closeNav}
              svg={<Store fill="#7f7f7f" width="1.3em" />}
              name="Stores"
              right={statistics?.store}
              to="/stores"
            />
            <EachLink
              onClick={CTX.closeNav}
              svg={<AiOutlineShopping size="1.3em" />}
              name="Orders"
              right={statistics?.order}
              to="/my-orders"
            />
            <EachLink
              svg={<AiOutlineWallet size="1.3em" />}
              name="Wallet"
              right={
                <Left
                  width="1.2em"
                  style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                />
              }
              onClick={onClickTransactionHandler}
            />
            <div
              style={{ height: showTransaction ? "max-content" : "0px" }}
              className={Classes.semiLinkCover}
            >
              <EachLink
                onClick={CTX.closeNav}
                svg={<Income size="1.3em" />}
                style={{ margin: "0px ", padding: "0px" }}
                name="Income"
                to="/income"
              />
              <EachLink
                onClick={CTX.closeNav}
                svg={<Withdrawal size="1.3em" />}
                style={{
                  margin: "10px 0px 0px 0px ",
                  padding: "10px 0px 0px 0px ",
                }}
                name="Withdrawal"
                to="/withdrawal"
              />
            </div>

            <EachLink
              svg={<AiOutlineSetting size="1.3em" />}
              name="Settings"
              style={{
                marginTop: !showTransaction && "0px",
                paddingTop: !showTransaction && "5px",
              }}
              right={
                <Left
                  width="1.2em"
                  style={{ fill: "#7F7F7F", transform: "rotate(-90deg)" }}
                />
              }
              onClick={onClickSettingHandler}
            />
            <div
              style={{ height: showSettings ? "max-content" : "0px" }}
              className={Classes.semiLinkCover}
            >
              {/* <EachLink
              svg={<AiOutlineWallet size="1.3em" />}
              style={{margin: '0px ', padding: '0px'}}
              name="Account"
              to="/settings?type=account"
            /> */}
              <button
                onClick={() => navigate("/settings?type=account")}
                style={{ textDecoration: "none" }}
              >
                <div
                  onClick={() => CTX.closeNav()}
                  style={{ margin: "0px ", padding: "0px" }}
                  className={`${
                    window.location.href.includes("type=account") &&
                    "!text-[#ff0066]"
                  } ${Classes.EachLinkCover}`}
                >
                  <AiOutlineWallet size="1.3em" />
                  <h5
                    className={` ${
                      window.location.href.includes("type=account") &&
                      "!text-[#ff0066]"
                    }`}
                  >
                    Account
                  </h5>
                </div>
              </button>
              {/* <EachLink
              svg={<USD size="1.3em" />}
              style={{
                margin: '10px 0px 0px 0px ',
                padding: '10px 0px 0px 0px ',
              }}
              name="Withdrawal"
              to="/settings?type=withdrawal"
            /> */}
              <button
                onClick={() => navigate("/settings?type=withdrawal")}
                style={{ textDecoration: "none", display: "block" }}
              >
                <div
                  onClick={() => CTX.closeNav()}
                  style={{
                    margin: "10px 0px 0px 0px ",
                    padding: "10px 0px 0px 0px ",
                  }}
                  className={`${
                    window.location.href.includes("type=withdrawal") &&
                    "!text-[#ff0066]"
                  } ${Classes.EachLinkCover}`}
                >
                  <AiOutlineDollarCircle
                    size="1.3em"
                    className={`${
                      window.location.href.includes("type=withdrawal") &&
                      "!text-[#ff0066]"
                    } `}
                  />
                  <h5
                    className={`${
                      window.location.href.includes("type=withdrawal") &&
                      "!text-[#ff0066]"
                    } `}
                  >
                    Withdrawal
                  </h5>
                  <strong></strong>
                </div>
              </button>
            </div>
            <EachLink
              svg={<AiOutlineLogout size="1.3em" />}
              onClick={(e) => {
                e.preventDefault();
                removeSideBar();
                setShowModal(!showModal);
              }}
              name="Log Out"
            />
          </div>
        </div>
      </aside>
      <Modal show={showModal} close={() => setShowModal(false)}>
        <h3 className={Classes.confirmRemoval}>Log out of Passward</h3>
        <p className={Classes.removed}>
          This will log you out of your account, all your data will be saved.
          Continue to Log Out?
        </p>

        <div style={{ display: "flex" }}>
          <Button
            text="Yes, Log Out"
            style={{ width: "160px" }}
            onClick={() => {
              CTX.logout();
              setShowModal(false);
            }}
          />
          <div style={{ width: "20px" }}></div>
          <Button
            text="Don't Log Out"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: "inherit",
              border: "2px solid #ff0066",
              color: "#ff0066",
              width: "160px",
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;

const EachLink = ({ onClick, name, svg, right, to = "/", style }) => (
  <>
    {to ? (
      <NavLink to={to} onClick={onClick} style={{ textDecoration: "none" }}>
        <div style={style} className={Classes.EachLinkCover}>
          {svg}
          <h5>{name}</h5>
          <strong>{right}</strong>
        </div>
      </NavLink>
    ) : (
      <div style={style} className={Classes.EachLinkCover}>
        {svg}
        <h5>{name}</h5>
        <strong>{right}</strong>
      </div>
    )}
  </>
);
