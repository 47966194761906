import React, {useContext, Suspense, lazy} from 'react';

import Classes from './layout.module.css';
import Fallback from '../suspense/suspense'
import Nav from './nav/nav';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MainContext} from '../../App';
import Sidebar from './sidebar/sidebar';
const  Withdrawal =  lazy(() => import('../../pages/withdrawal/eventWithdrawal'));
const  Orders =  lazy(() => import('../../pages/orders/eventOrders'));
const  EachOrder =  lazy(() => import('../../pages/eachOrder/eventEachOrder'));
const  Income =  lazy(() => import('../../pages/income/eventIncome'));
const  Store =  lazy(() => import('../../pages/store/eventStore'));
const  Settings =  lazy(() => import('../../pages/settings/settings'));
const  Dashboard =  lazy(() => import('../../pages/dashboard/dashboard'));
const  Customer =  lazy(() => import('../../pages/customer/eventCustomer'));

const Layout = () => {
  const removeSideBar = () => {
    document.body.removeAttribute('data-side-navigation-mode');
    document.body.classList.remove('fade-effect');
  };

  const CTX = useContext(MainContext);
 
  return (
    <div className={Classes.allLayoutCover}>
      <Nav />
  <Sidebar/>
  <div onClick={CTX.closeNav} className={`fixed   lg:hidden top-0 left-0 z-[2] ${!CTX.sidebar&&'hidden'} h-full w-full`} style={{backdropFilter:'blur(5px)',backgroundColor:'rgba(255,255,255,0.7)'}}/>
      {/* <div onClick={removeSideBar} className={Classes.premium}>
      <small>
        Premium Subscription coming Soon...experience unlimited...
      </small>
    </div> */}
      <div className={Classes.layoutCover}>
        <div onClick={removeSideBar} style={{height: '20px'}}></div>
        <div className={Classes.splitSidebarCover}>
          <div className={`lg:pl-[290px]  ${Classes.bodySectionSection}`}> 
            {/* // import all pages */}
            <Routes>
            {CTX.token && (
                <Route
                  path="/login"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}
              {!CTX.token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
              {CTX.token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/dashboard'} />}
                />
              )}
              {CTX.token && <Route path="/dashboard" element={<Suspense fallback={<Fallback/>}> <Dashboard /> </Suspense>} />}
              {CTX.token && <Route path="/customers" element={<Suspense fallback={<Fallback/>}> <Customer /> </Suspense>} />}
              {CTX.token && <Route path="/withdrawal" element={<Suspense fallback={<Fallback/>}> <Withdrawal /> </Suspense>} />}
              {CTX.token && <Route path="/my-orders" element={<Suspense fallback={<Fallback/>}> <Orders /> </Suspense>} />}
              {CTX.token && <Route path="/my-orders/:id" element={<Suspense fallback={<Fallback/>}> <EachOrder /> </Suspense>} />}
              {CTX.token && <Route path="/income" element={<Suspense fallback={<Fallback/>}> <Income /> </Suspense>} />}
              {CTX.token && <Route path="/stores" element={<Suspense fallback={<Fallback/>}> <Store /> </Suspense>} />}
              {CTX.token && <Route path="/settings" element={<Suspense fallback={<Fallback/>}> <Settings /> </Suspense>} />}
              
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
