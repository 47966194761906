import React, { useContext, useState } from "react";
import Classes from "./verify.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Button from "../../component/button/button";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { MainContext } from "../../App";

const Verify = () => {
  const CTX = useContext(MainContext);
  const [code, setCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [ref] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  // handler for the inputs
  const onChangeValue = (newValue, index) => {
    if (newValue.match(/(\d|^(?![\s\S]))/g) && newValue.length <= 2) {
      setValue([
        ...value.slice(0, index),
        newValue.slice(-1),
        ...value.slice(index + 1),
      ]);
      if (newValue.length > 0 && ref[index + 1]) {
        ref[index + 1].focus();
      }
    }
  };

  const onRemove = (key, index) => {
    if (key === "Backspace") {
      ref[index - 1].focus();
    }
  };

  const RequestCodeHandler = () => {
    setTimeout(() => {
      setLoading(false);
      setCode(true);
    }, 3000);
    setLoading(true);
  };

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Verify - Passward Agent </title>
        <meta name="og:title" content="Verify - Passward Agent" />
        <meta name="og:description" content="Verify - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <div
          autoComplete="off"
          className={Classes.formCover}
          //    onSubmit={onSubmitHandler}
        >
          <h1 className={Classes.header}>
            {code ? "Enter Code" : "Verify Account"}
          </h1>
          <p className={Classes.subHeader}>
            {code
              ? "We have sent a 6 digit code to your mail. Copy and paste here to verify your account"
              : "Thanks for creating account with us. Please use the button below to request for a verification link"}
          </p>

          {code && (
            <div className={Classes.allInputCover}>
              <input
                ref={(input) => {
                  ref[1] = input;
                }}
                value={value[1]}
                onChange={({ target }) => onChangeValue(target.value, 1)}
                onKeyUp={({ key }) => onRemove(key, 1)}
              />
              <input
                ref={(input) => {
                  ref[2] = input;
                }}
                value={value[2]}
                onChange={({ target }) => onChangeValue(target.value, 2)}
                onKeyUp={({ key }) => onRemove(key, 2)}
              />
              <input
                ref={(input) => {
                  ref[3] = input;
                }}
                value={value[3]}
                onChange={({ target }) => onChangeValue(target.value, 3)}
                onKeyUp={({ key }) => onRemove(key, 3)}
              />
              <input
                ref={(input) => {
                  ref[4] = input;
                }}
                value={value[4]}
                onChange={({ target }) => onChangeValue(target.value, 4)}
                onKeyUp={({ key }) => onRemove(key, 4)}
              />
              <input
                ref={(input) => {
                  ref[5] = input;
                }}
                value={value[5]}
                onChange={({ target }) => onChangeValue(target.value, 5)}
                onKeyUp={({ key }) => onRemove(key, 5)}
              />
              <input
                ref={(input) => {
                  ref[6] = input;
                }}
                value={value[6]}
                onChange={({ target }) => onChangeValue(target.value, 6)}
                onKeyUp={({ key }) => onRemove(key, 6)}
              />
            </div>
          )}

          {code ? (
            <Button
              text="Verify"
              loading={loading}
              style={{ height: "38px", marginTop: "20px" }}
            />
          ) : (
            <Button
              text="Request Code"
              onClick={RequestCodeHandler}
              loading={loading}
              style={{ height: "38px", marginTop: "20px" }}
            />
          )}

          <div className={Classes.ResendStrong}>Resend Code</div>
        </div>
        <img src={SemiLogo} className={Classes.topRightLogo} />
        <img src={RightLogo} className={Classes.rightLogo} />
        <img src={RightLogo} className={Classes.leftLogo} />
        <img src={RightLogo} className={Classes.topLeftLogo} />
        <img src={SemiLogo} className={Classes.semiLogo} />
      </div>
    </div>
  );
};

export default Verify;
