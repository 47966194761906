import axios from "axios";
import React from "react";
import { createContext } from "react";
import { useReducer } from "react";
import GlobalReducer from "./GlobalReducer";

const initialState = {
  statistics: null,
};
export const GlobalContext = createContext(initialState);

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const getStatistics = async () => {
    try {
      const request = await axios.get(
        `${process.env.REACT_APP_BASEURL}/agent/dashboard/statistics`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${sessionStorage.getItem("a_tk")}`,
          },
        }
      );
      dispatch({
        type: "STORE_STATISTICS",
        payload: request.data.data,
      });
      sessionStorage.setItem("stats", JSON.stringify(request.data.data));
    } catch (error) {
      dispatch({
        type: "STORE_STATISTICS",
        payload: null,
      });
      console.log(error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{ statistics: state.statistics, getStatistics }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
