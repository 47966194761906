import React from "react";

import Classes from "./forgot.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Input from "../../component/input/input";
import Button from "../../component/button/button";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Login = ({ inputs, setInputs, onSubmitHandler, msg, loading, CTX }) => {
  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Forgot Password - Passward Agent </title>
        <meta name="og:title" content="Forgot Password - Passward Agent" />
        <meta
          name="og:description"
          content="Forgot Password - Passward Agent"
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>Forgot Password</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={onSubmitHandler}
        >
          {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{
                textAlign: "center",
                marginBottom: "40px",
                display: "none",
              }}
            >
              <p style={{ margin: "0px 0px" }} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}

          <p className={Classes.EnterSection}>
            Enter a recovery email to reset your password
          </p>
          <div className={Classes.main_width}>
            <Input
              type="input"
              placeholder="yourmail@domain.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              required={true}
              labelStyle={{ color: "#000033" }}
              label="Recovery Email"
              style={{ backgroundColor: "#fff", height: "50px" }}
            />
          </div>

          {/* {!register && ( */}
          <div className={Classes.main_width} style={{ marginTop: "18px" }}>
            <Button
              text="Request Reset Link"
              style={{ width: "100%", height: "40px", marginBottom: "50px" }}
              loading={loading}
            />
          </div>

          <Link to="/login">
            <div className={Classes.Back_to_Login}>Back to Login</div>
          </Link>

          {/* )} */}
        </form>

        <img src={SemiLogo} className={Classes.topRightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.rightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.leftLogo} alt="logo" />
        <img src={RightLogo} className={Classes.topLeftLogo} alt="logo" />
        <img src={SemiLogo} className={Classes.semiLogo} alt="logo" />
      </div>
    </div>
  );
};

export default Login;
