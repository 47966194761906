import React, { useState, useContext, useEffect } from "react";
import Classes from "./verificationPage.module.css";
import SemiLogo from "../../asset/logo_semi.png";
import RightLogo from "../../asset/star.png";
import Logo from "../../asset/logo.png";
import Button from "../../component/button/button";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../App";
import { ReactComponent as VerificationSVG } from "../../asset/verification.svg";
import OTPInput from "react-otp-input";

const VerificationPage = () => {
  const CTX = useContext(MainContext);
  const [counter, setCounter] = useState(0);
  let navigate = useNavigate();
  const [register, setRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState({ otp: "" });
  const otpStyle = {
    height: "45px",
    width: "45px",
    border: "1px solid white",
    outline: "none",
    backgroundColor: "white",
    margin: "10px",
    color: "black",
    padding: "10px",
  };

  //   the main button handler here
  const submitHandler = async (e) => {
    e.preventDefault();
    if (register) {
      return navigate("/login");
    } else {
      setLoading(true);
      try {
        const fetched = await fetch(
          `${process.env.REACT_APP_BASEURL}/agent/account/verify`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: otp.otp,
            }),
          }
        );

        const json = await fetched.json();
        console.log("json", json);
        setLoading(false);
        if (json.message && json.status === 404) {
          toast(json?.message?.message ?? "");
          return;
        } else {
          setRegister(true);
        }
      } catch (error) {
        setLoading(false);
        toast("Check your internet connections");
        console.log(error);
      }
    }
  };

  // resend code function
  const resendCode = async () => {
    try {
      if (counter > 2) {
        return;
      }

      setCounter(60);
      const fetched = await fetch(
        `${process.env.REACT_APP_BASEURL}/agent/account/verify/resend`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: localStorage.getItem("email"),
          }),
        }
      );

      const json = await fetched.json();
      console.log("json", json);
      setLoading(false);
      if (json.detail) {
        toast("Email sent!");
        return;
      }
      toast("Verification email has been sent to your email");
    } catch (error) {
      setLoading(false);
      toast("Check your internet connections");
      console.log(error);
    }
  };

  useEffect(() => {
    if (counter < 1) {
      return;
    } else {
      setTimeout(() => {
        const calc = counter - 1;
        setCounter(calc);
      }, 1300);
    }
  }, [counter]);

  return (
    <div className={Classes.LoginFormCover}>
      <Helmet>
        <title>Verification - Passward Agent </title>
        <meta name="og:title" content="Verification - Passward Agent" />
        <meta name="og:description" content="Verification - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <form
          onSubmit={submitHandler}
          autoComplete="off"
          className={Classes.formCover}
        >
          <h1 className={Classes.header}>
            {register ? "Account Verified" : "Enter Code"}
          </h1>
          <p className={Classes.semiNoteHere}>
            {register
              ? "Your account has been successfully verified"
              : "We have sent a 6 digit code to your mail. Copy and paste here to verify your account"}
          </p>

          {!register && (
            <div className={Classes.allInputCover}>
              <OTPInput
                value={otp.otp}
                onChange={(otp) => setOtp({ otp })}
                numInputs={6}
                inputStyle={otpStyle}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          )}

          {register && <VerificationSVG />}

          <div className={Classes.main_width} style={{ marginTop: "18px" }}>
            <Button
              onClick={submitHandler}
              text={register ? "Login" : "Verify"}
              style={{ width: "130px", height: "40px", margin: "auto" }}
              loading={loading}
            />
            {!register && (
              <div
                onClick={resendCode}
                style={{ color: counter === 0 ? "#ff0066" : "#ff006694" }}
                className={Classes.resendCode}
              >
                {counter === 0
                  ? "Resend Code"
                  : "Resend in the next " + counter + "s"}
              </div>
            )}
          </div>
          {/* )} */}
        </form>
        <img src={SemiLogo} className={Classes.topRightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.rightLogo} alt="logo" />
        <img src={RightLogo} className={Classes.leftLogo} alt="logo" />
        <img src={RightLogo} className={Classes.topLeftLogo} alt="logo" />
        <img src={SemiLogo} className={Classes.semiLogo} alt="logo" />
      </div>
    </div>
  );
};

export default VerificationPage;
