const GlobalReducer = (state, action) => {
    switch (action.type) {
        case "STORE_STATISTICS":
            return {
                ...state,
                statistics: action.payload
            }
        default:
            return state
    }
}

export default GlobalReducer