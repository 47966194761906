import Layout from "./component/layout/layout";
import Login from "./pages/login/eventLogin";
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Verify from "./pages/verify/verify";
import { Toaster } from "react-hot-toast";
import VerificationPage from "./pages/verificationPage/verificationPage";
import EventForgot from "./pages/forgotPassword/forgotLogin";
import EventReset from "./pages/resetPassword/eventReset";
import { useReducer } from "react";
import GlobalProvider from "./context/GlobalProvider";
const initialState = {
  url: null,
  user: null,
  token: null,
  openLogin: () => {},
  closeLogin: () => {},
  checkLogin: () => {},
  setToken: () => {},
  removeSideBar: () => {},
  logout: () => {},
  setUser: () => {},
  setStats: () => {},
  stats: null,
  sidebar: false,
};
function App() {
  const url = process.env.REACT_APP_BASEURL;
  const [token, setToken] = useState(sessionStorage.getItem("a_tk") || "");
  const [stats, setStats] = useState(
    JSON.parse(sessionStorage.getItem("stats")) || {}
  );
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || {}
  );

  const openLogin = () => {
    document.body.setAttribute("data-side-login-mode", "open");
  };

  const closeLogin = () => {
    document.body.removeAttribute("data-side-login-mode", "open");
  };

  const checkLogin = () => {
    if (token.length < 3) {
      openLogin();
    } else {
      return;
    }
  };

  const setTokenHandler = (_token) => {
    setToken(_token);
    closeLogin();
    sessionStorage.setItem("a_tk", _token);
  };

  const setStatsHandler = (_payload) => {
    sessionStorage.setItem("stats", JSON.stringify(_payload));
    setStats(_payload);
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem("user", JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken("");
    setUser({});
  };
  const Reducer = (state, action) => {
    switch (action.type) {
      case "CLOSE_NAV":
        return { ...state, sidebar: action.payload };
      case "OPEN_NAV":
        return { ...state, sidebar: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(Reducer, initialState);
  const closeNav = () => {
    dispatch({
      type: "CLOSE_NAV",
      payload: false,
    });
  };

  const openNav = () => {
    dispatch({
      type: "OPEN_NAV",
      payload: true,
    });
  };

  return (
    <MainContext.Provider
      value={{
        url,
        openLogin,
        closeLogin,
        checkLogin,
        token,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        setStats: setStatsHandler,
        stats,
        sidebar: state.sidebar,
        openNav,
        closeNav,
      }}
    >
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            background: "#ff0066",
            borderLeft: "5px solid #003",
            fontSize: "14px",
            color: "#fff",
          },
        }}
      />
      <Routes>
        {!token && (
          <Route path="/" exact element={<Navigate replace to={"/login"} />} />
        )}
        {token && (
          <Route
            path="/login"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        <Route path="/verify" exact element={<Verify />} />
        <Route path="/forgot-password" exact element={<EventForgot />} />
        {/* <Route path="/reset-password/:token"  element={<EventReset />} /> */}
        <Route path="/reset-password/:token" element={<EventReset />} />

        {!token && <Route path="/login" element={<Login />} />}
        {!token && (
          <Route path="/verification" element={<VerificationPage />} />
        )}
        {!token && (
          <Route path="/*" exact element={<Navigate replace to={"/login"} />} />
        )}
      </Routes>

      {token && (
        <GlobalProvider>
          <Layout />
        </GlobalProvider>
      )}
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext(initialState);

//  on the store, not everything their has thier end point from the API
//  no edit eachStore section
//  no edit eachStore section
// signed income wallet
// wallet home/income/product
// PERSONAL INFORMATION uneder withdrawal

// NEW ERRORS
// NEW ERRORS
// NEW ERRORS
// NEW ERRORS

// send the bank code together with bank details in the settings page in also together with vendor and logistics
// details not working after saving changes in the backend
// even the password is not working!
// the income page from the API is working now!
// the income page the revenue and the selecting of months from the top nav is not working... add it add the selecting of months
// change  the signed column in the same income page in the vendor to store plan
// take out the customer in the same income page in the vendor on top!

// CURRENT ERRORS ON GROUND!
// sidebar is not showing!

// under profile
// profile photeo not working OGA JOHNSON!
// under profile, the phone number not showing when a user signs up newly

// under store,
// Adding a store, remove the description not product feature
